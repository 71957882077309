/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */

//# Functions

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@function in-list($list, $var) {
	@return (false == index($list, $var));
}


//# General/Reusable

/**
 * Extend element's background to left and right edges of viewport.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
 @mixin bleed($position: relative, $direction: both) {
	@if $position {
		position: $position;
	}

	$commonSelector: "";
	$bleedLeft: false;
	$bleedRight: false;

	@if $direction == left {
		$commonSelector: "&:before";
		$bleedLeft: true;
	}
	@else if $direction == right {
		$commonSelector: "&:after";
		$bleedRight: true;
	}
	@else if $direction == both {
		$commonSelector: "&:before, &:after";
		$bleedLeft: true;
		$bleedRight: true;
	}
	@else {
		@error "Invalid $direction: #{$direction}";
	}

	#{$commonSelector} {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100vw;
		background: inherit;
		content: " ";
		border: inherit;
	}
	@if $bleedLeft {
		&:before {
			right: 100%;
		}
	}
	@if $bleedRight {
		&:after {
			left: 100%;
		}
	}
} // bleed

/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
@mixin lock($properties, $width-range, $min-value, $max-value) {

	$min-vw: nth($width-range, 1);
	$max-vw: nth($width-range, 2);
	$range-units: unit($min-vw);

	@each $property in $properties {
		#{$property}: $min-value;
	}

	@media screen and (min-width: $min-vw) {
		@each $property in $properties {
			#{$property}: calc(#{$min-value}
							   + #{strip-unit($max-value - $min-value)}
							   * (100vw - #{$min-vw})
							   / #{strip-unit($max-vw - $min-vw)});
		}
	}

	@media screen and (min-width: $max-vw) {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}

/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
@mixin inline-list($separator: '|', $selector: '> li', $spacing: .6em) {
	#{$selector} {
		display: inline-block;
		list-style: none;

		&:not(:last-child):after {
			display: inline-block;
			vertical-align: middle;
			content: '#{$separator}';
			margin: 0 #{$spacing};
		}
	}
}

@mixin gutter($sides, $type: margin, $multiple: 1) {
	@if ($type != margin) and ($type != padding) {
		@error "Invalid gutter type.";
	}

	@if ($sides == all) or (length($sides) == 4) {
		#{$type}: map-get($grid-column-gutter, 'small') * $multiple;

		@include breakpoint(medium) {
			#{$type}: map-get($grid-column-gutter, 'medium') * $multiple;
		}
	} // if
	@else {
		@each $side in $sides {
			#{$type}-#{$side}: map-get($grid-column-gutter, 'small') * $multiple;
		} // each side

		@include breakpoint(medium) {
			@each $side in $sides {
				#{$type}-#{$side}: map-get($grid-column-gutter, 'medium') * $multiple;
			} // each side
		}
	} // else
} // gutter()

/**
 * Hide visually, but keep accessible for input focus, screen readers, etc.
 */
@mixin visually-hidden() {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	padding: 0 !important;
	border: 0 !important;
	margin: -1px !important;
}


//# Titles

@mixin primary-title() {
	color: $primary-color;
	font-size: rem-calc(40.08);
	font-weight: normal;
	line-height: 1.2;
	margin: 0 0 1.5rem;
}

@mixin small-caps-title() {
	color: $de-gray-dark;
	font-family: $body-font-family;
	font-size: $small-caps-title-size;
	font-weight: bold;
	letter-spacing: rem-calc(1);
	text-transform: uppercase;
	margin: 0;
}

@mixin small-caps-serif-title() {
	color: $de-brown;
	font-family: $roboto-slab-font;
	font-size: $small-caps-title-size;
	letter-spacing: rem-calc(1);
	text-transform: uppercase;
	margin: 0;
}


//# Touchscreens

/**
 * Add min-width/height for touchable elements. This should usually be used within
 * an "@media (pointer: coarse)" block.
 */
@mixin touchable-min-size() {
	min-width: $touchable-size-minimum;
	min-height: $touchable-size-minimum;
}

//# Spinners & Animations

@mixin ripple-spinner($size) {
	width: $size;
	height: $size;

	&,
	&:before,
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	&:before,
	&:after {
		content: ' ';
		opacity: 1;
		animation: expand-and-fade-out 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		border: rem-calc(4) solid #fff;
		border-radius: 50%;
	}
	&:after {
		animation-delay: -0.5s;
	}
} // ripple-spinner
@keyframes expand-and-fade-out {
	0% {
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}


//# DEPRECATED

/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
@mixin transition($transition...) {
	@warn "Transition() mixin is deprecated now that we use autoprefixer. Please use standard CSS transition property.";

	// defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-ms", "-o",  "");

	@each $prefix in $prefixes {
		#{$prefix}-transition: $transition;
	}

	transition: $transition;
}

/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: linear-gradient(to bottom, $from, $to);
}

@mixin full-bleed {
	width: 100vw;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
}

@mixin absolute-full {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
} // absolute-ful

@mixin form-sep {
	width: 100%;
	height: 2px;
	background: rgba($de-rust, 0.5);
	margin: 2rem 0;
} // form-sep

@mixin vsep {
	width: 2px;
	height: auto;
	background: rgba($de-rust, 0.5);
} // form-sep

@mixin tooltip-top {
	position: absolute;
	display: flex;
	align-items: center;
	visibility: hidden;
	bottom: 125%;
	top: -100%;
	left: 50%;
	width: max-content;
	max-width: 16rem;
	z-index: 1;
	background-color: $de-gray-dark;
	color: $white;
	opacity: 0;
	transition: opacity $hover-transition;
	transform: translateX(-50%);
	font-size: 0.75rem;
	font-weight: 700;
	letter-spacing: rem-calc(.5);
	text-align: center;
	padding: rem-calc(5) rem-calc(10);
	border-radius: rem-calc(6);

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		border-width: rem-calc(5);
		border-style: solid;
		border-color: $de-gray-dark $transparent $transparent $transparent;
	} // &::after
}

@mixin button-trash {
	width: 1.5rem;
	margin-left: 1rem;
	cursor: pointer;
	svg {
		fill: $de-gray;
		width: 100%;
		height: auto;
	} // svg

} // button-trash

@mixin ajax-loading($opacity: 0.85) {
	@include absolute-full;
	display: none;
	opacity: 0;
	background: rgba($de-gray, $opacity);
	z-index: 1;
	transition: opacity 0.3s ease-in-out;

	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		left: 0;
	} // span

	svg {
		@include absolute-center;
		shape-rendering: auto;
		animation-play-state: running;
		animation-delay: 0s;
	} // svg

	&.is-open {
		opacity: 1;
	} // *.is-open
}

@mixin bullet-points() {
	margin-bottom: $paragraph-margin-bottom;

	&::before {
		display: inline-block;
		position: relative;
		top: rem-calc(1);
		width: 1.3em;
		content: '◼';
		color: $primary-color;
		margin-left: -1.3em;
	} // &::before
}

@mixin modal-close-button() {
	position: absolute;
	background: $black;
	color: $white;
	cursor: pointer;	
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	right: -1rem;
	top: -1rem;
	line-height: 2.2rem;
	font-size: 3rem;
	z-index: 2;
}